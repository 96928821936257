.app__services {
    flex: 1;
    width: 100%;
    flex-direction: column;
}

.app__service-filter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;

    margin: 4rem 0 2rem;

    .app__service-filter-item {
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        background-color: #fff;
        color: #000;
        font-weight: 800;
        font-size: 0.9rem;
        cursor: pointer;
        transition: all 0.3s ease;
        margin: 0.5rem;

        &:hover {
            background-color: var(--purple-color);
            color: #fff;
        }

        @media screen and (min-width: 2000px) {
            padding: 1rem 2rem;
            border-radius:  0.85rem;            
        }

    }
    .item-active {
        background-color: var(--purple-color);
        color: #fff;
    }

}

.app__service-portfolio {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;

    .app__service-item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 500px;
        margin: 2rem;
        border-radius: 0.5rem;
        background-color: white;
        color: black;

        transition: all 0.3s ease;
       

        @media screen and (max-width: 500px) {
            width: 100%;
            margin: 1rem;
            
        }

        h4 {
            margin-top: 1rem;
            line-height: 1.5;
            position: relative;
            background-color: var(--purple-color);
            align-items: center;
            align-self: stretch;
            text-align: center;
            padding: 1rem;
            color: var(--white-color);
            top: -1rem;
            border-top-left-radius: 0.5rem;
            border-top-right-radius: 0.5rem;

        }
    
        p {
            font-size: 1rem;
            padding: 0.5rem 1.5rem 1.5rem;        
            @media screen and (min-width: 2000px) {
            font-size: 1.05rem;
            
        }
        }

    }
   
      
}


.app__service-content {
    padding: 0.5rem;
    width: 100%;
    position: relative;
    flex-direction: column;
    
    h4 {
        margin-top: 1rem;
        line-height: 1.5;
        position: absolute;
        background-color: var(--purple-color);
        padding: 1rem 8rem;
        color: var(--white-color);
        top: -24px;
    }

    p {
        padding-top: 4rem;
        font-size: 1rem;
    }
}

// .app__services-full-list {
//     padding: 1rem 1.5rem;
//     background-color: var(--white-color);
//     border-radius: 10px;

//     a {
//         text-decoration: none;
//         color: #000;

//     }
//     &:hover {
//         box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
//     }
// }

.app__about {
    display: flex;
    flex-direction: column;

}

.app__profiles {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.app__profile-item {
    width: 60%;
    display: flex;
    flex-direction: row;
    background-color: var(--white-color);
    align-items: center;
    margin: 1rem;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);

    
    p {
        flex: 1;
        font-size: 1.2rem;
    }
    @media screen and (max-width: 1300px) {
        width: 80%;
        
    }
    @media screen and (max-width: 900px) {
        width: 100%;
        
    }
    @media screen and (max-width: 600px) {
        flex-direction: column;
        p {
            font-size: 1.1rem;
        }
    }

    @media screen and (min-width: 2000px) {
        width: 50%;
        
    }
    
}
@media screen and (min-width: 1300px) {
.app__profile-item:not(:first-child) {
    width: 55%;
    @media screen and (min-width: 2000px) {
        width: 45%;
    }
}
}
.app__profile-spacer {
    width: 1rem;
    height: 1rem;
}

.app__profile-photo {
    flex: 0.7;
    img {
        width: 100%;
        border-radius: 0.5rem;
        
    }
    @media screen and (min-width: 2000px) {
        flex: 0.4;
    }
}

.app__profile-item:nth-child(even) {
    flex-direction: row-reverse;
    @media screen and (max-width: 600px) {
        flex-direction: column;
        
    }
    
}

.app__profile-item:first-child {
    align-items: flex-start;

}

.app__profile-icons {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;

    img {
        width: 30px;

        @media screen and (max-width: 600px) {
            width: 25px;
            
        }
    }
}
.app__testimonial {
    flex: 1;
    width: 100%;
    flex-direction: column;
    margin-top: 1rem;

    @media screen and (max-width: 1000px ) {
        h2 {
            max-width: 630px;
        }
    }
    @media screen and (max-width: 750px) {
        span {
            display: block;
        }
    }
   
}



.app__testimonial-item {
    width: 60%;
    min-height: 320px;
    background-color: var(--white-color);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);

    img {
        width: 180px;
        height: 180px;
        border-radius: 50%;
        object-fit: cover;

        @media screen and (max-width: 600px) {
            margin-bottom: 1rem;
            
        }
    }

    @media screen and (min-width: 2000px) {
        width: 55%;
        img { 
            width: 250px;
            height: 250px;
        }
        
    }
    @media screen and (max-width: 1700px) {
        width: 85%;
    }

    @media screen and (max-width: 1200px) {
        width: 100%;

    }

    @media screen and (max-width: 600px) {
        flex-direction: column;
        
        margin-top: 2rem;
    }
}

.app__testimonial-content {
    flex: 1;
    height: 100%;
    
    padding: 0 2rem;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: stretch;

    @media screen and (max-width: 600px) {
        padding: 0 0;
        
    }

    p {
        font-size: 1.2rem;
        line-height: 2rem;
        color: var(--black-color);
        font-family: var(--font-base);

    
    }

  

    h5 {
        font-weight: 400;
        color: var(--gray-color);
        margin-top: 5px;
        font-size: 1.1rem;
    }
    
    div {   
             
        margin-top: 2rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        h4 {
            font-weight: 600;
            color: var(--purple-color);
        }
        
        p { 
            text-align: right;
            color: var(--gray-color);
            font-size: 0.78rem;
            
        }

        @media screen and (max-width: 500px) {
            flex-direction: column;
            align-items: flex-start;
            p {
                text-align: left;
            }
        }
    }
}

.app__testimonial-btns {
    flex-direction: row;
    margin-top: 1rem;

    div {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: var(--white-color);
        margin: 1rem;

        transition: all 0.3s ease-in-out;

        svg {
            width: 20px;
            height: 20px;
            color: var(--purple-color);
        }
        &:hover {
            background-color: var(--purple-color);

            svg {
                color: var(--white-color);
            }
        }
    }
}

.app__testimonials-stickers {
    width: 80%;
    flex-wrap: wrap;
    margin-top: 1rem;

    div {
        width: 150px;
        margin: 1.5rem;
        background-color: var(--white-color);
        border-radius: 7px;
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
        padding: 1rem;

        img {
            width: 100%;
            height: auto;
            object-fit: cover;
            border-radius: 5px;
        }
        &:hover {
            img {
                filter: grayscale(0);
            }
        }

       
        @media screen and (max-width: 450px) {
            width: 120px;
            margin: 1rem;
            
        }
        
    }

    @media screen and (max-width: 800px) {
        width: 100%;
        
    }
}
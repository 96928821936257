@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');


:root {
  --font-base: "DM Sans", sans-serif;
  --primary-background: #DBCDC680;
  --menu-background: #DBCDC6;
  /* --primary-background: #edf2f8; */
  --secondary-background: #EAD7D180;
  /* --purple-color: #7B506F; */
  /* --purple-color: #513449; */
  --purple-color: #9E2C7f;
  --lightPurple-color: #f4d6eb;
  --darkBlue-color: #1F1A38;
  --lightBlue-color: #e2dff0;
  --primary-color: #edf2f8;
  --secondary-color: #313bac;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #4b5361;
  --brown-color: #46364a;
  --white-color: #ffffff;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
.app__associates {
    flex: 1;
    width: 100%;
    flex-direction: column;
}

.app__associate-filter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;

    margin: 4rem 0 2rem;

    .app__associate-filter-item {
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        background-color: #fff;
        color: #000;
        font-weight: 800;
        font-size: 0.9rem;
        cursor: pointer;
        transition: all 0.3s ease;
        margin: 0.5rem;

        &:hover {
            background-color: var(--purple-color);
            color: #fff;
        }

        @media screen and (min-width: 2000px) {
            padding: 1rem 2rem;
            border-radius:  0.85rem;            
        }

    }
    .item-active {
        background-color: var(--purple-color);
        color: #fff;
    }

}

.app__associate-portfolio {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;

    .app__associate-item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 400px;
        margin: 2rem;
        padding: 0.5rem 1rem 1rem;
        border-radius: 0.5rem;
        background-color: white;
        color: black;

        transition: all 0.3s ease;
       
        @media screen and (min-width: 2000px) {
            padding: 1.25rem;
            margin: 3rem;
            border-radius: 0.75rem;
            
        }
        @media screen and (max-width: 500px) {
            width: 100%;
            margin: 1rem;
            
        }

    }
   
      
}


.app__associate-content {
    padding: 0.5rem;
    width: 100%;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    
    
    h4 {
        align-self: center;
        margin: 1rem;
        font-size: 1.2rem;
        line-height: 1.5;
        color: var(--purple-color);
    }

  
    ul {
        align-self: flex-start;
        margin-left: 2rem;
    }
    a {
        display: block;
        text-decoration: none;
        color: #000;
        padding: 0.3rem;

        &:hover {
            color: var(--purple-color);
        }
    }
}
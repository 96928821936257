@import 'node_modules/react-modal-video/scss/modal-video.scss';


#home {
    position: relative;
    background: url('../../assets/header_background.png');
    background-size: cover;
    background-repeat: repeat;
    background-position: center;

    .app__wrapper {
        padding: 0;

        .copyright {
            display: none;
        }
    }
}

.app__header {
    flex: 1;
    width: 100%;
    height: 100%; 
    flex-direction: row;

    padding: 6rem 2rem 0;

    @media screen and (min-width: 2000px) {
        padding-top: 8rem;
    }
    @media screen and (max-width: 1200px) {
        flex-direction: column;
    }
    @media screen and (min-width: 450px) {
        padding: 6rem 1rem 2rem;
    }
}

.app__header-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%; 

    h2 {
        background-color: var(--white-color);
        padding: 1.5rem 2.5em;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
        border-radius: 1rem;
    }

    @media screen and (max-width: 2000px) {
        width: 100%;
        margin-right: 0rem;

        

    }

    @media screen and (max-width: 800px) {
        h2 {
            padding: 1.5rem 0.5rem;
        }
        
    }
}


#magic {
    font-family: 'Dancing Script', cursive;
    font-size: larger;
}

.app__header-notes {
    display: flex;
    align-self: flex-end;
    flex-direction: column;
    align-items: flex-end;
    @media screen and (max-width: 600px) {
        align-self: center;
        align-items: center;
        margin-bottom: 1.5rem;
        
    }
}

.app__header-button {
    color: var(--gray-color);
    border: none;
    padding: 1.2rem;
    background: var(--white-color);
    border-radius: 7px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        background-color: var(--purple-color);
        color: var(--white-color);
    }

   
}

.app__header-img-attribution {
    margin-top: 1rem;
    color: var(--white-color);
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 0.25rem;
    font-size: smaller;
}